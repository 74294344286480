import { Application } from '@hotwired/stimulus';
import '../stylesheets/tailwind.scss';

import '@hotwired/turbo-rails';

import '../src/turbo';
import '../src/modernizr-custom';

// /* eslint-disable import/first */
import '../components';
// /* eslint-enable import/first */

const application = Application.start();

import CollapseController from '../../components/collapse_controller.js';
import PopupController from '../../components/popup_controller';
import FormController from '../../components/form_controller';
import NavbarController from '../controllers/navbar_controller';
import RecaptchaV2Controller from '../controllers/recaptcha-v2';

application.register('form', FormController);
application.register('navbar', NavbarController);
application.register('collapse', CollapseController);
application.register('popup', PopupController);
application.register('recaptcha-v2', RecaptchaV2Controller);
